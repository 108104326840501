import React from "react"

import { Container, Row, Col } from "reactstrap"

import Layout from "../components/Layout"
import PageHeader from "../components/PageHeader"
import PageFooter from "../components/PageFooter"

import { StaticImage } from "gatsby-plugin-image"

import SEO from "../components/SEO"
import WipeScreen from "../components/WipeScreen"

const SecondPage = () => (
  <>
    <SEO title={"PGM | eProjectCare"} slug={"pmg-eproject-care"} />
    <Row className="white-container case-study">
      <Container className="mt-5 pt-5">
        <Row className="mt-5 pt-5 no-gutters">
          <Col sm="8" className="super-margin">
            <h1>
              PMG - eProjectCare: File Storage & Collaboration Platform for
              Civil Engineering
            </h1>
            <br />
            <p>Case Study</p>
          </Col>
        </Row>
      </Container>
    </Row>
    <Row className="case-study-content">
      <Container
        className="hero-container"
        style={{ background: "#423b3e", marginBottom: 20 }}
        fluid
        tag="section"
      >
        <StaticImage
          src="../images/pmg/web_1.png"
          alt="web_3"
          width={1600}
          quality={100}
          formats={["auto", "webp"]}
        />
      </Container>

      <Container className="article-content" tag="section" fluid>
        <Container>
          <Row className="inter-section">
            <Col sm="12">
              <Col sm="6" className="p-0 section-text float-left">
                <h3>01 Challenge</h3>
                <p>
                  eProjectCare is a comprehensive document management and
                  collaboration software designed to cater to the needs of large
                  format printing oriented on civil engeneering management.
                </p>
                <p>
                  The legacy software required a thorough redesign and
                  modernization to meet contemporary standards, ensuring an
                  optimal user experience.
                </p>
                <p>
                  This overhaul aimed at delivering superior document management
                  and seamless collaboration capabilities, aligning with the
                  document management and collaboration requirements of numerous
                  PMG's clients.
                </p>
              </Col>
              <Col
                sm={{ size: 4, offset: 2 }}
                className="p-0 section-text float-left"
              >
                <h4>Scope</h4>
                <p className="gray">Frontend development / Product design</p>
                <h4>Date</h4>
                <p className="gray">2016 - 2017</p>
                <h4>Client & location</h4>
                <p className="gray">PMG GmbH / Munich, Germany</p>
              </Col>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container
        className="imagery-content"
        style={{
          background: "#423b3e",
          marginBottom: 30,
        }}
        fluid
        tag="section"
      >
        <Row className="mb-5 pb-5">
          <Col xs="12">
            <h3 className="white mb-5 center">
              Document Management & Collaboration
            </h3>
          </Col>
          <Col sm="12">
            <StaticImage
              src="../images/pmg/web_2.png"
              alt="web_3"
              width={1600}
              quality={100}
              formats={["auto", "webp"]}
            />
          </Col>
          <Col sm="12" className="center">
            <StaticImage
              src="../images/pmg/web_5.png"
              alt="web_3"
              width={1300}
              quality={100}
              formats={["auto", "webp"]}
            />
          </Col>
        </Row>
      </Container>
      <Container
        className="imagery-content"
        style={{
          background: "",
          marginBottom: 30,
        }}
        fluid
        tag="section"
      >
        <Row className="mb-5 pb-5">
          <Col xs="12">
            <h3 className="center">User Interface Elements</h3>
          </Col>
          <Col sm="1" className="mt-5"></Col>
          <Col sm="10" className="mt-5 center">
            <StaticImage
              src="../images/pmg/ui_2.png"
              alt="ui_2"
              width={1200}
              quality={100}
              formats={["auto", "webp"]}
            />
          </Col>
        </Row>
      </Container>
      <Container
        className="article-content"
        tag="section"
        fluid
        style={{ background: "transparent" }}
      >
        <Container>
          <Row className="pt-5 mt-5 mb-5 pb-3">
            <Col sm="4" className="p-0 section-text float-left">
              <div className="pr-4 pl-4">
                <h4>Projects</h4>
                <p>
                  Multiple projects accesible within one account. An overview,
                  quick actions and easy access to details.
                </p>
                <br />
                <h4>Documents</h4>
                <p>
                  File and directory navigation with list, tile and tree view,
                  quick action buttons and drag & drop.
                </p>
              </div>
            </Col>
            <Col sm="4" className="p-0 section-text float-left">
              <div className="pr-4 pl-4">
                <h4>Messaging System</h4>
                <p>
                  Messaging teams & stakeholders enabled within platform with
                  easy access to documents.
                </p>
                <br />
                <h4>Calendar Management</h4>
                <p>
                  Elaborated system to manage personal and project calendars,
                  events and appointments.
                </p>
              </div>
            </Col>
            <Col sm="4" className="p-0 section-text float-left">
              <div className="pr-4 pl-4">
                <h4>Task Management</h4>
                <p>
                  Personal, team and group tasks within a project. Subtasks,
                  priority, status tracking, comments and more.
                </p>
                <br />
                <h4>Team Management</h4>
                <p>
                  Facilitates the organization of members into working groups,
                  promoting smoother collaboration.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container
        className="imagery-content last-imagery"
        style={{ background: "#423b3e", marginBottom: 20 }}
        fluid
        tag="section"
      >
        <Container>
          <h3 className="white center">Messaging System</h3>
        </Container>
        <div className="center">
          <StaticImage
            src="../images/pmg/web_4.png"
            alt="web_3"
            width={1600}
            quality={100}
            formats={["auto", "webp"]}
          />
        </div>{" "}
        <div className="work-image center">
          <StaticImage
            src="../images/pmg/web_7.png"
            alt="web_3"
            width={1300}
            quality={100}
            formats={["auto", "webp"]}
          />
        </div>{" "}
      </Container>
      <Container
        className="imagery-content last-imagery"
        style={{ background: "#423b3e", marginBottom: 20 }}
        fluid
        tag="section"
      >
        <Container>
          <h3 className="white center mb-5">Calendar Management</h3>
        </Container>
        <div className="work-image center">
          <StaticImage
            src="../images/pmg/web_6.png"
            alt="web_3"
            width={1300}
            quality={100}
            formats={["auto", "webp"]}
          />
        </div>
        <div className="center">
          <StaticImage
            src="../images/pmg/web_3.png"
            alt="web_3"
            width={1600}
            quality={100}
            formats={["auto", "webp"]}
          />
        </div>
      </Container>
      <Container className="imagery-content last-imagery" fluid tag="section">
        <div className="circles">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <Container>
          <Row align="center" className="mt-5">
            <Col sm="4">
              <h2>6</h2>
              <p>Team Members</p>
            </Col>
            <Col xs="6" sm="4">
              <h2>1.5 yrs</h2>
              <p>Duration</p>
            </Col>
            <Col xs="6" sm="4">
              <h2>2017</h2>
              <p>Launch Date</p>
            </Col>
          </Row>
        </Container>
      </Container>
    </Row>
    <WipeScreen />
    <PageFooter project="pmg" />
  </>
)

SecondPage.Layout = Layout
export default SecondPage
